import api from "../services/api";

// Получить схемы погрузки (с информацией) для отображения в результатах плана
export async function getPlanTransportResultLoadingScheme(
  width: number,
  loadingSchemeId: any,
  parentId: any
) {
  return await api.get("/PlanContainerResultLoadingScheme/GetLoadingScheme", {
    params: {
      width,
      loadingSchemeId,
      IdParent: parentId,
    },
  });
}
